<!--TooltipTextOver-->
<template>
  <s-tooltip
    ref="instance"
    theme="studio-overflow-tooltip"
    :arrow="hasArrow"
    :content="content"
    :placement="position"
    :duration="0"
    :zIndex="2510"
    useFlip
    flipOnUpdate
    trigger="mouseenter focus"
    :class="`flex items-center w-auto max-w-full ${customCss || ''}`"
    @mount="checkShowToolTip"
    @show="addTippyClass"
  >
    <template #target>
      <slot></slot>
    </template>
  </s-tooltip>
</template>
<script lang="ts" setup>
import { defineProps, nextTick, ref } from 'vue';

const props = withDefaults(
  defineProps<{
    content: string;
    tippyClass?: string,
    hasArrow?: boolean;
    forceShow?: boolean;
    customCss?: string;
    position:
      | 'top'
      | 'bottom'
      | 'right'
      | 'left'
      | 'top-start'
      | 'top-end'
      | 'bottom-start'
      | 'bottom-end';
  }>(),
  {
    position: 'top',
    tippyClass: '',
    customCss: ''
  }
);

const instance = ref();

const checkShowToolTip = ($event: any) => {
  if (props.forceShow) {
    return;
  }

  nextTick(() => {
    if (instance.value) {
      const $tooltip = instance.value.$el;
      const $tooltipTarget = $tooltip.children[0];
      const $wrapper = $tooltipTarget || $tooltip;

      if (
        $wrapper.scrollWidth <= $wrapper.clientWidth &&
        $wrapper.scrollHeight <= $wrapper.clientHeight
      ) {
        $event.disable();
      }
    }
  });
};

const addTippyClass = ($event: any) => {
  if (props.tippyClass) {
    const $tooltip = $event.popper;
    $tooltip.classList.add(props.tippyClass);
  }
};
</script>
